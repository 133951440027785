@import "colors.less";
@import "print.less";
@import "font-awesome.min.less";
@import "primeng.less";
@font-face {
  font-family: "Circular";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/circular-book.woff") format("woff"), url("../fonts/circular-book.ttf") format("truetype");
}

@font-face {
  font-family: "Circular";
  font-weight: normal;
  font-style: italic;
  src: url("../fonts/circular-bookitalic.woff") format("woff"), url("../fonts/circular-bookitalic.ttf") format("truetype");
}

@font-face {
  font-family: "Circular";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/circular-medium.woff") format("woff"), url("../fonts/circular-medium.ttf") format("truetype");
}

@font-face {
  font-family: "Circular";
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/circular-mediumitalic.woff") format("woff"), url("../fonts/circular-mediumitalic.ttf") format("truetype");
}

@font-face {
  font-family: "Circular";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/circular-bold.woff") format("woff"), url("../fonts/circular-bold.ttf") format("truetype");
}

@font-face {
  font-family: "Circular";
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/circular-bolditalic.woff") format("woff"), url("../fonts/circular-bolditalic.ttf") format("truetype");
}

@font-face {
  font-family: "Circular";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/circular-black.woff") format("woff"), url("../fonts/circular-black.ttf") format("truetype");
}

@font-face {
  font-family: "Circular";
  font-weight: 800;
  font-style: italic;
  src: url("../fonts/circular-blackitalic.woff") format("woff"), url("../fonts/circular-blackitalic.ttf") format("truetype");
}

h1, h2, h3, h1 span, h2 span, h3 span, h4, h4 span, label {
  font-family: "Circular", sans-serif;
}

:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

.dash-title {
  padding-bottom: 10px;
  h3 {
    padding: 0px;
    margin: 4px 0px 4px 0px;
    font-size: 25px;
  }
}

.show-mobile {
  display: none;
  visibility: hidden;
}

.pull-right {
  float: right;
}

a {
  color: @ness_color_2 ;
  text-decoration: none;
  font-family: "Circular", sans-serif;
  font-weight: normal;
}

.red {
  color: @red;
}

a:hover {
  color: @ness_color_2;
  text-decoration: none;
}

.overlay-scroll-content {
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
}

body {
  font-family: "Circular", sans-serif;
  padding: 0px;
  margin: 0px;
  background-color: @background;
  color: @text-color;
}

p.small {
  font-size: 12px;
  padding: 0px;
  line-height: 14px;
  margin: 0px;
  margin-top: 2px;
}

p.detail {
  font-size: 13px;
  padding: 0px;
  line-height: 14px;
  margin: 0px;
  margin-top: 4px !important;
  i {
    font-size: 13px;
    color: @ness_color_3;
  }
}

p a {
  color: @ness_color_3;
  font-family: "Circular";
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.primary-color {
  i {
    color: @ness_color_3;
  }
}

.header {
  background-color: @red;
  padding: 0px 20px;
  color: @white;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  line-height: 60px;
  height: 80px;
  z-index: 100;
  width: 100%;
  h1 {
    margin: 0px;
    padding: 0px;
    font-size: 30px;
    line-height: 60px;
    font-family: "Circular", sans-serif;
  }
  .logo {
    float: left;
    margin-right: 30px;
  }
}

.fc-right {
  margin-left: auto;
}

.no-margin {
  margin: 0px;
}

.p-fluid .p-button {
  width: auto;
  min-width: 32px;
}

.works {
  width: 100%;
  margin: 0px;
  td {
    padding: 12px 0px;
    border-bottom: 1px solid #ccc;
  }
}

.user {
  background-color: @ness_color_2;
  padding: 8px 10px !important;
  border-radius: @radius;
  color: @white;
  margin-right: 10px;
  &:hover {
    background-color: darken(@green, 20%);
  }
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: fade(@ness_color_2, 20%);
  border-color: fade(@ness_color_2, 20%);
  color: @text-color;
}

.welcome-banner {
  border-radius: @radius;
  padding: 20px 30px;
  color: @white;
  background: url(src/assets/images/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  .img-banner {
    width: 260px;
  }
  h3 {
    margin: 0px;
    padding: 0px;
  }
  h1 {
    color: @white;
  }
}

.tab-menu {
  padding: 10px 20px 0px 20px;
  .p-tabmenu {
    overflow-y: none !important;
    overflow: none !important;
    height: 50px !important;
  }
  .p-tabmenu .p-tabmenu-nav {
    background-color: transparent !important;
  }
  p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    background-color: transparent !important;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background-color: transparent !important;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    border-bottom-width: 2px !important;
  }
}

.sidebar {
  height: 100%;
  width: 80px;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  background: @white;
  overflow-x: hidden;
  transition: 0.1s;
  padding-top: 0px;
  border-right: 1px solid #ebedf0;
  .openbtn {
    font-size: 22px;
    cursor: pointer;
    color: @ness_color_2;
    width: 77px;
    height: 66px;
    border: none;
    text-align: center;
    line-height: 66px;
    float: right;
    margin-left: 0px;
    border-right: 0px solid #ccc;
    i {
      color: @ness_color_2;
      background-color: #efefef;
      width: 32px;
      border-radius: 5px;
      height: 32px;
      line-height: 32px;
      padding: 0px;
    }
  }
  .logo-wrapper {
    float: left;
    margin-left: 30px;
    top: 6px;
    width: 130px;
    position: relative;
    height: 57px;
    padding-right: 0px;
    border-right: 0px solid #dee2e6;
    font-size: 26px;
    background: url(src/assets/images/logo.svg) no-repeat left center;
    background-size: 84%;
    font-weight: bold;
    img {
      height: 30px;
      line-height: 60px;
      position: relative;
      top: 15px;
    }
    .color-dot {
      color: @white;
      text-transform: uppercase;
      font-size: 10px;
      background-color: @ness_color_3;
      padding: 3px;
      border-radius: 4px;
      position: relative;
      padding-left: 5px;
      padding-right: 5px;
      top: -3px;
      margin-left: 3px;
    }
  }
  .user-menu {
    position: absolute;
    list-style-type: none;
    margin: 0px;
    margin-top: 20px;
    padding: 0px !important;
    margin-right: 0px;
    bottom: 0px;
    right: 15px;
    li {
      float: left;
      list-style: none;
      font-size: 14px;
      width: 40px !important;
      height: 40px;
      border-radius: @radius;
      margin-bottom: 10px;
      padding: 0px;
      line-height: 40px;
      color: @ness_color_2;
      text-align: center;
      i {
        margin-right: 2px;
      }
      &.logout {
        background-color: fade(@ness_color_2, 20%);
        margin-bottom: 10px;
        color: @ness_color_2;
      }
      a {
        color: @white;
      }
    }
  }
  &.open {
    width: 80px;
    .logo-wrapper {
      display: none !important;
      visibility: hidden !important;
    }
   
    .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
      margin-right: 0.5rem;
      float: right;
      position: absolute;
      right: 5px;
      display: none;
      visibility: hidden;
     }
     .p-panelmenu .p-menuitem-text {
      line-height: 1;
      display: none;
      visibility: hidden;
    }
    .p-panelmenu {
      padding: 0px 15px;
      font-family: "Circular", sans-serif;
  }

  .color-switch {
    position: absolute;
    bottom: 20px;
    left: 15px;
}
    ul {
      li {
        i {
          float: none;
        }
        a {
         
          &:hover {}
          i {
            margin: 0px !important;
          }
        }
      }
    }
  }
  &.close {
    width: 260px;
    ul {
      li {
        a {
          span {
            display: inherit !important;
            visibility: visible !important;
          }
          &:hover {}
          i {}
        }
      }
    }
  }
}

.product-item {
  margin-bottom: 0px;
  display: block;
  .p-card {
    .p-card-body {
      padding: 0px;
      .image-home img[class] {
        width: 100%;
      }
      .image-home img {
        max-height: 180px;
        background-color: #444;
        border-radius: 4px 0px 0px 4px;
      }
      .fade-in {
        animation-name: fadeIn;
        animation-duration: 1.3s;
        animation-timing-function: cubic-bezier(0, 0, .4, 1);
        animation-fill-mode: forwards;
      }
      .object-fit_cover {
        object-fit: cover;
      }
      .product-item-description {
        padding: 20px;
        span.price {
          background-color: @ness_color_3;
          padding: 0.25rem 0.4rem;
          font-size: 13px;
          border-radius: 3px;
          font-weight: 600;
          color: @white;
        }
        h3 {
          font-size: 16px;
          font-family: "Circular", sans-serif;
          font-weight: 500;
        }
      }
    }
  }
}

.item-size {
  padding: 5px 0px;
}

.settings-card {
  .p-card {
    border: 1px solid @white;

    .p-card-body {
      .p-card-content {
        i {
          color: @ness_color_3;
        }
        h3 {
          padding: 0px 0px 8px 0px;
          margin: 0px;
          margin-top: 10px;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }

    &:hover {
      border: 1px solid @ness_color_2;
    }
  }
}

.filter-tag {
  background-color: @white;
  border-radius: 4px;
  padding: 2px 0px 2px 8px;
  margin-right: 6px;
  color: @ness_color_2;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  .close {
    background-color: @ness_color_2;
    border-radius: 0px 4px 4px 0px;
    width: 8px;
    padding: 2px 6px;
    height: 8px;
    i {
      color: #FFF !important;
      font-size: 11px;
    }
  }
}

ul.ngx-pagination {
  margin: 0 auto;
  width: 100%;
  padding: 10px 0px;
  text-align: center;
  li {
    border-radius: 4px;
    height: 30px !important;
    line-height: 30px !important;
    &.current {
      background: @ness_color_3;
      border-radius: 4px;
      height: 30px;
      color: #fefefe;
      cursor: default;
      padding: 0px 9px !important;
      min-width: 30px;
    }
    &.disabled {
      background-color: transparent !important;
      span {
        &:hover {
          background: transparent !important;
          color: @ness_color_1;
        }
      }
    }
    a {
      padding: 0px 9px !important;
      min-width: 30px;
    }
    :hover {
      background: @ness_color_2;
      border-radius: 4px;
      color: #fefefe;
      cursor: default;
    }
  }
}

ul.nav-menu-mobile {
  list-style: none;
  padding: 0px;
  display: inline-block;
  margin: 0px;
  padding-top: 0px;
  overflow-y: auto !important;
  li {
    list-style-type: none;
    float: left;
    width: 100%;
    padding: 0px;
    text-align: center;
    a {
      padding: 8px 14px;
      text-decoration: none;
      font-size: 15px;
      color: @ness_color_2;
      display: block !important;
      visibility: visible;
      text-align: center;
      transition: 0.3s;
      span {
        float: left;
        transition: visibility 0.1s;
      }
      &:hover {}
      i {
        float: left;
        font-size: 15px;
        margin-right: 0px;
        text-align: center;
        border: 1px solid transparent;
        margin-right: 0.5rem;
        width: 20px;
        text-align: center;
      }
      &:hover {
        i {
          float: left;
          font-size: 20px;
          width: 40px;
          height: 40px;
          color: @white;
          border-radius: @radius;
          text-align: center;
          color: @white;
          border: 1px solid @ness_color_3;
        }
      }
      &.active {
        i {
          float: left;
          font-size: 20px;
          width: 40px;
          height: 40px;
          color: @white;
          background-color: @ness_color_3;
          border-radius: @radius;
          text-align: center;
          border: 1px solid @ness_color_3;
        }
      }
    }
  }
}

.featured-card {
  font-family: "Circular", sans-serif;
  .p-card {
    background-color: @white;
    color: @ness_color_2;
    border: 1px solid @ness_color_3;
    .p-card-body {
      padding: 25px;
      font-family: "Circular", sans-serif;
      h4 {
        color: @ness_color_2;
      }
      .icon-container {
        margin-bottom: 10px;
        i {
          font-size: 30px;
          color: @ness_color_3;
        }
      }
    }
  }
}

.appointment-card {
  .p-card {
    margin-bottom: 10px;
    border: 0px;
    box-shadow: none;
    border-radius: @radius;
    box-shadow: none !important;
  }
  .p-card-body {
    background-color: @background;
    border: 0px;
    border-radius: @radius;
    border: 0px solid #dee2e6;
    box-shadow: none !important;
  }
  .icon {
    background-color: @ness_color_3;
    color: @white;
    height: 44px;
    line-height: 48px;
    width: 44px;
    border-radius: @radius;
    text-align: center;
    i {
      font-size: 20px;
    }
  }
}

.helpdesk-card {
  .p-card {
    margin-bottom: 10px;
    border: 0px;
    box-shadow: none;
    border-radius: @radius;
    box-shadow: none !important;
  }
  .p-card-body {
    background-color: @white;
    border: 0px;
    border-radius: @radius;
    border: 1px solid fade(@ness_color_2, 20%);
    transition: .2s;
    box-shadow: none !important;
    &:hover {
      border: 1px solid @ness_color_3;
    }
  }
  .icon {
    background-color: @ness_color_3;
    color: @white;
    height: 44px;
    line-height: 48px;
    width: 44px;
    border-radius: @radius;
    text-align: center;
    i {
      font-size: 20px;
    }
  }
}

.top-main-wrapper {
  background-color: @white;
  margin-bottom: 0px;
  padding: 15px 20px;
  border-bottom: 0px solid #dee2e6;
  border-top: 0px solid #dee2e6;
  &.with-sidebar {
    &.left {
      margin-left: 450px;
    }
    &.right {
      margin-right: 450px;
    }
  }
  .dash-header {
    padding: 0px;
    margin: 0px;
  }
  .dash-title {
    padding-bottom: 0px;
    h3 {
      padding: 0px;
      margin: 4px 0px 4px 0px;
      font-size: 20px;
    }
  }
  .p-field {
    margin-bottom: 0rem;
  }
}


.title-main-wrapper {
  background-color: #FFF;
  margin-bottom: 20px;
  padding: 0px 20px 0px 20px;
  border-bottom: 0px solid #dee2e6;
  border-top: 0px solid #dee2e6;
  .dash-header {
    padding: 0px;
    margin: 0px;
  }
  .dash-title {
    padding-bottom: 0px;
    h3 {
      padding: 0px;
      margin: 4px 0px 4px 0px;
      font-size: 20px;
    }
  }
  .p-field {
    margin-bottom: 0rem;
  }
}


#main {
  transition: margin-left 0.1s;
  margin-left: 80px;
  padding: 0px;
  padding-top: 66px;
  margin-right: 0px;
  .navbar-top {
    height: 66px;
    background-color: @white;
    position: fixed;
    z-index: 1000;
    top: 0px;
    border-bottom: 1px solid #dee2e6;
    width: 100%;

    .language-settings {
      list-style: none;
      float: right;
      margin-right: 30px;
      margin-top: 15px;
      
    }

    .user-menu {
      list-style: none;
      float: right;
      margin-right: 20px;
      margin-top: 10px;
      li {
        list-style-type: none;
        font-size: 16px;
        float: left;
        margin-left: 15px;
        i {
          color: fade(@ness_color_2, 40%);
          margin-right: 5px;
        }
        .support-tag {
          margin-right: 5px;
        }
      }
    }
  }
  .main-inner {
    padding: 20px 20px 30px 20px;
    &.with-sidebar {
      &.left {
        margin-left: 450px;
      }
      &.right {
        margin-right: 450px;
      }
    }
  }
  &.open {
    margin-left: 260px;
    .sidebar-inside.left {
      left: 260px;
      border-right: 1px solid #dee2e6;
    }
    .navbar-top {
      width: calc(100% - 260px);
    }
  }
  &.close {
    margin-left: 80px;
    .sidebar-inside.left {
      left: 80px;
      border-right: 1px solid #dee2e6;
    }
    .navbar-top {
      width: calc(100% - 80px);
    }
  }
}



.section-wrapper {
  padding: 0px 40px 0px 40px;
  float: left;
  border-right: 0px solid #ccc;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      padding: 0px 0px 0px 0px;
      height: 66px;
      line-height: 66px;
      list-style-type: none;
      float: left;
      margin-right: 30px;
      &.active {
        border-bottom: 5px solid @ness_color_3;
      }
    }
  }
}

.cp {
  cursor: pointer;
}

.search-wrapper {
  padding: 15px 20px 15px 0px;
  width: 25vw;
  float: left;
  .p-inputtext {
    background-color: @background;
    border: 0px;
    padding-left: 15px;
    border-radius: @radius;
  }
}

hr.full {
  border: 0px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

hr.line {
  border: 0px;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
  width: calc(100% - 40px);
  margin-top: 0px;
}

.read {
  width: 6px;
  height: 6px;
  border-radius: 30px;
  float: right;
  &.yes {
    background-color: @red;
  }
  &.no {
    background-color: @green;
  }
}

.button_outer {
  background: fade(@ness_color_2, 10%);
  border-radius: @radius;
  text-align: center;
  height: 50px;
  width: 100%;
  display: inline-block;
  transition: .2s;
  position: relative;
  border: 2px dotted fade(@ness_color_2, 30%);
  overflow: hidden;
}


.button_outer_big {
  background: fade(@ness_color_2, 10%);
  border-radius: @radius;
  text-align: center;
  width: 100%;
  display: inline-block;
  transition: .2s;
  position: relative;
  padding: 10px;
  border: 2px dotted fade(@ness_color_2, 30%);
  overflow: hidden;
  margin-bottom: 30px;
}

.btn_upload {
  padding: 14px 30px 12px;
  color: @ness_color_2;
  text-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 14px;
  z-index: 3;
  white-space: nowrap;
}

.btn_upload input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 105%;
  cursor: pointer;
  font-family: "Circular", sans-serif;
  opacity: 0;
}

.login-wrap {
  height: 100vh;
  background-image: url(src/assets/images/immo.jpeg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  position: relative;
}

.login-box {
  position: absolute;
  right: 0px;
  box-shadow: none;
  background-color: @white;
  width: 100%;
  max-width: 50vw;
  height: 100%;
  .logo-wrapper {
    float: left;
    margin-left: 0px;
    top: 8px;
    height: 50px;
    border-left: 0px solid #ccc;
    margin-bottom: 30px;
    img {
      height: 50px;
    }
  }
  .alert.alert-danger {
    background-color: @red;
    margin-bottom: 10px;
    padding: 8px 14px;
    color: @white;
    border-radius: @radius;
  }
  .login-box-inner {
    padding: 30px 70px;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    margin-top: 30%;
    
    input {
      width: 100%;
      line-height: 2;
      height: 48px;
      padding-left: 14px;
      border-radius: @radius;
    }
    button {
      width: 100%;
      display: block;
      border: none;
      border-radius: @radius;
      color: white;
      height: 48px;
      background-color: @ness_color_4;
    }
    h1 {
      span {
        font-family: "Circular";
        font-weight: normal;
      }
    }
  }
}

.p-timeline {
  max-height: 700px;
  overflow: hidden;
  overflow-y: auto;
  .p-timeline-event {
    min-height: auto !important;
  }
  .p-timeline-event-marker {
    border: 2px solid @ness_color_3;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #ffffff;
  }
}

.support-tag {
  padding: 3px 7px;
  margin-left: 6px;
  background-color: @ness_color_3;
  border-radius: @radius;
  color: @white;
  height: 20px;
  width: 20px;
  font-size: 13px;
  position: relative;
  top: -2px;
}

.color-dot {
  color: @white;
  text-transform: uppercase;
  font-size: 10px;
  background-color: @ness_color_3;
  padding: 3px;
  border-radius: 4px;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  top: -3px;
  margin-left: 3px;
}

.p-timeline-event-opposite {
  display: none;
  visibility: hidden;
}

.p-datatable .p-datatable-tbody > tr:hover {
  background-color: #f7faff;
}

.p-card {
  background: #ffffff;
  color: @text-color;
  box-shadow: @shadow !important;
  border-radius: @radius;
  border: 0px solid #dee2e6;
  margin-bottom: 20px;
  .p-card-body {
    padding: 15px;
    .p-card-content {
      padding: 0px;
      h3 {
        padding: 0px 0px 8px 0px;
        margin: 0px;
        font-size: 20px;
      }
      hr {
        border: 0px;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}

.projects {
  .p-card {
    background: @ness_color_2;
    color: @text-color;
    border-radius: @radius;
    border: 0px solid #dee2e6;
    margin-bottom: 20px;
    background-color: @white;
    color: @ness_color_2;
    border: 0px solid fade(@ness_color_2, 20%);
    .p-card-body {
      padding: 25px 25px;
      .p-card-content {
        h4 {
          margin-bottom: 10px;
        }
        padding: 0px;
        color: @ness_color_2;
        label.legend {
          color: fade(@ness_color_2, 60%);
          font-weight: normal;
        }
        h3 {
          padding: 0px 0px 0px 0px;
          margin: 0px;
          font-size: 20px;
          color: @ness_color_2;
        }
        hr {
          border: 0px;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}

.count-item {
  font-size: 12px;
  background-color: @background;
  padding: 0.4rem 0.5rem;
  border-radius: @radius;
}

.blu-box {
  .p-card {
    background: @ness_color_2;
    color: @text-color;
    box-shadow: none;
    border-radius: @radius;
    border: 0px solid #dee2e6;
    border-left: 8px solid fade(@ness_color_3, 90%);
    margin-bottom: 20px;
    background-color: @white;
    color: @ness_color_2;
    border: 1px solid @ness_color_3;
    .p-card-body {
      padding: 15px;
      .p-card-content {
        padding: 0px;
        color: @ness_color_2;
        label.legend {
          color: fade(@ness_color_2, 60%);
          font-weight: normal;
        }
        h3 {
          padding: 0px 0px 0px 0px;
          margin: 0px;
          font-size: 20px;
          color: @ness_color_2;
        }
        hr {
          border: 0px;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}

.well {
  border-radius: @radius;
  padding: 10px 10px;
  background-color: fade(@ness_color_3, 6%);
  p {
    margin: 0px;
    padding: 0px;
  }
}

.p-tabview {
  .p-tabview-nav {
    li {
      &.p-highlight {
        .p-tabview-nav-link {
          color: @ness_color_2;
          border-color: transparent transparent @ness_color_2
        }
      }
    }
  }
}

.main {
  transition: margin-left 0.1s;
  padding: 60px 60px;
  margin-left: 64px;
  &.open {
    margin-left: 64px;
  }
  &.close {
    margin-left: 64px;
  }
}

.dash-header {
  padding: 30px 0px 0px 0px;
  .p-card {
    .p-field {
      margin: 0px !important;
    }
  }
}

.sidebar-inner {
  height: calc(100% - 61px);
  width: 80px;
  position: absolute;
  z-index: 1;
  top: 61px;
  right: 0px;
  background-color: @white;
  border-left: 1px solid #dee2e6;
  overflow-x: hidden;
  transition: 0.1s;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.sidebar-inside {
  height: 100%;
  width: 450px;
  position: fixed;
  z-index: 1;
  border-right: 1px solid #dee2e6 !important;
  top: 66px;
  background-color: @white;
  overflow-x: hidden;
  transition: 0.1s;

  overflow-y: auto;
  &.right {
    right: 0px;
    border-left: 1px solid #dee2e6;
  }
  &.left {
    left: 260px;
    border-right: 1px solid #dee2e6;
  }
  
  .sidebar-inside-inner {
    padding: 20px;
  }

}

.sidebar-inside-large {
  height: 100%;
  width: 650px;
  position: fixed;
  z-index: 1;
  border-right: 1px solid #dee2e6 !important;
  top: 66px;
  background-color: @white;
  overflow-x: hidden;
  transition: 0.1s;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  overflow-y: auto;
  &.right {
    right: 0px;
    border-left: 1px solid #dee2e6;
  }
  &.left {
    left: 260px;
    border-right: 1px solid #dee2e6;
  }
  h3 {
    padding: 0px;
    margin: 0px;
    margin-bottom: 0px;
  }
}

.sidebar-main {
  height: 100%;
  width: 260px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 64px;
  background-color: #f5f5f5;
  overflow-x: hidden;
  transition: 0.1s;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  .title-wrapper {
    float: left;
    margin-left: 0px;
    color: @ness_color_2;
    font-size: 16px;
    font-weight: 400;
    padding-top: 0px;
    font-family: "Circular";
    font-weight: 500;
    height: 66px;
    line-height: 66px;
    h3 {
      padding: 0px;
      padding-top: 0px;
      margin: 0px;
      line-height: 66px;
    }
  }
  .information-box {
    list-style-type: none;
    float: left;
    width: 100%;
    background-color: @white;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 4px;
    font-size: 13px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
    h4 {
      font-size: 15px;
      margin: 0px;
      font-family: "Circular";
      font-weight: 500;
    }
  }
  ul {
    list-style: none;
    padding: 20px 0px;
    li {
      list-style-type: none;
      float: left;
      width: 100%;
      margin-bottom: 5px;
      padding: 10px 0px;
      border-bottom: 1px solid fade(@ness_color_2, 10%);
      font-size: 15px;
      font-family: "Circular";
      font-weight: 500;
      i {
        margin-right: 5px;
        opacity: 0.6;
      }
      a {
        color: @ness_color_2;
        text-decoration: none;
        -webkit-transition: color 300ms, color 300ms;
        -moz-transition: color 300ms, color 300ms;
        -o-transition: color 300ms, color 300ms;
        transition: color 300ms, color 300ms;
        font-size: 18px;
        &:hover {
          color: @ness_color_3;
        }
        &.active {
          color: @ness_color_3;
        }
      }
      h4 {
        font-size: 15px;
        margin: 0px;
      }
    }
  }
  &.open {
    width: 260px;
    ul {
      li {
        a {
          &:hover {}
          i {}
        }
      }
    }
  }
  &.close {
    width: 0px;
    padding-left: 0px;
    padding-right: 0px;
    ul {
      li {
        a {
          &:hover {}
          i {}
        }
      }
    }
  }
}

.info-window {
  overflow: hidden;
  width: 200px;
  height: 210px;
  img {
    width: 200px;
    height: 100px;
  }
  span.price {
    background-color: @ness_color_3;
    padding: 0.25rem 0.4rem;
    font-size: 13px;
    border-radius: 3px;
    font-weight: 600;
    color: @white;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  width: 100%;
}

.p-sidebar {
  h1, h2, h3, h1 span, h2 span, h3 span {
    font-family: "Circular", sans-serif;
    margin-top: 0px;
  }
}

.p-datatable .p-datatable-footer {
  background: #FFF;
  color: @text-color;
  border: 0px solid #e9ecef !important;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-header {
  background: #FFF;
  color: @text-color;
  border: 0px solid #e9ecef !important;
  border-width: 1px 0 1px 0;
  padding: 1rem 0rem;
  font-weight: 600;
  h5 {
    font-size: 20px;
  }
}

.p-timeline-event-content {
  .p-card {
    background-color: @background;
    color: @ness_color_2;
    box-shadow: none;
    border-radius: 6px;
    border: 0px solid #dee2e6;
    margin-bottom: 10px;
    .p-card-content {
      border: 0px;
    }
    .p-card-body {
      padding: 12px 15px;
      border: 0px;
    }
  }
}

.progress-project {
  .p-card {
    background-color: @white;
    color: @ness_color_2;
    box-shadow: none;
    border-radius: 6px;
    border: 1px solid #dee2e6;
    margin-bottom: 20px;
    .p-card-content {
      border: 0px;
    }
    .p-card-body {
      padding: 30px 15px !important;
      border: 0px;
      text-align: center;
      .number {
        font-size: 40px;
        font-weight: 600;
      }
    }
  }
}

.complete-project {
  .p-card {
    color: @white;
    box-shadow: none;
    border-radius: 6px;
    border: 0px solid #dee2e6;
    margin-bottom: 20px;
    .p-card-content {
      border: 0px;
    }
    .p-card-body {
      padding: 30px 15px !important;
      border: 0px;
      text-align: center;
      .number {
        font-size: 40px;
        font-weight: 600;
      }
    }
  }
  &.orange {
    .p-card {
      background-color: @yellow;
      border: 1px solid @yellow !important;
    }
  }

  &.volumio {
    .p-card {
      background-color: @red;
      border: 1px solid @red !important;
    }
  }

  &.green {
    .p-card {
      background-color: @green;
      border: 1px solid @green !important;
    }
  }
  &.white {
    .p-card {
      background-color: @red;
      color: @white !important;
      border: 1px solid #dee2e6 !important;
    }
  }
}

.homepage-wrapper {
  .information-box {
    list-style-type: none;
    float: left;
    width: 100%;
    background-color: @ness_color_3;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 4px;
    border: 0px solid #ccc;
    font-size: 13px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
    color: @white;
    font-size: 15px;
    font-family: "Circular";
    font-weight: 500;
  }
}

.fc-other-month {
  background-color: fade(@ness_color_3, 6%) !important;
}

.fc-day-grid-event {
  margin: 5px;
  padding: 0 1px;
}

.fc-day-number {
  padding-left: 5px;
}

.fc .fc-view-container .fc-event {
  background: fade(@white, 90%);
  box-shadow: @shadow;
  border: 1px solid #dee2e6;
  border-left: 6px solid @ness_color_3;
  color: @ness_color_2;
  padding: 10px;
  hr {
    border-color: @ness_color_3 !important;
  }
  i {
    font-size: 13px;
  }
  .fc-title {
    a {
      color: @ness_color_3 !important;
    }
  }
}

.fc .fc-view-container .fc-event .event-time {
  background: fade(@ness_color_3, 20%);
  border-radius: 6px;
  padding: 5px 8px;
  margin-bottom: 5px;
}

.fc .fc-toolbar .fc-button {
  color: @ness_color_2;
  background: transparent;
  border: 1px solid @ness_color_2;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.fc-icon {
  display: block;
  // Icon base
  &:after {
    display: inline-block;
    font-family: "FontAwesome";
    line-height: 1;
    font-size: 14px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// Arrow icons
.fc-icon-left-single-arrow:after {
  content: "\f053";
}

.fc-icon-right-single-arrow:after {
  content: "\f054";
}

.fc-icon-left-double-arrow:after {
  content: "\000AB";
}

.fc-icon-right-double-arrow:after {
  content: "\000BB";
}

// X icon
.fc-icon-x:after {
  content: "\ed6b";
  font-size: 14px;
  display: block;
}

.fc-icon.fa {
  font: normal normal normal 14px/1 FontAwesome !important;
}

.fc .fc-toolbar .fc-button:enabled:hover {
  background: @ness_color_3;
  color: #ffffff;
  border-color: @ness_color_3;
}

.fc-button {
  padding: 3px !important;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: @ness_color_2;
  border-color: @ness_color_2;
  color: #ffffff;
}

.billings-list {
  width: 100%;
  border: 0px;
  border-spacing: 0px;
  thead {
    tr {
      th {
        background-color: @background;
        text-align: left;
        padding: 7px 10px;
        font-size: 13px;
      }
    }
  }
  tr {
    td {
      border-top: 1px solid #ccc;
      padding: 10px 10px;
      h4 {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.p-panelmenu {

  padding: 0px 20px;
  font-family: "Circular", sans-serif;

}


.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: @radius;
  border-top-left-radius: @radius;
  font-family: "Circular", sans-serif;

}


.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
  float: right;
  position: absolute;
  right: 5px;
  
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
  width: 20px;
  text-align: center;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
  border-radius: @radius !important;
}


.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem;
  font-family: "Circular", sans-serif;

  border: 0px solid #dee2e6;
  color: @ness_color_2;
  background: @white;
  font-weight: 400;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 0px solid #dee2e6;
  background: #ffffff;
  color: @ness_color_2;
  margin-bottom: 0;
  border-top: 0;
  border-radius: 0px !important;
}


.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: @ness_color_3;
  border-color: #dee2e6;
  color: @white;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: @radius;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link a.p-menuitem-link-active {
  background: #e9ecef;
  border-color: #dee2e6;
}

.p-panelmenu .p-submenu-list {


  a {
  &.p-menuitem-link-active {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057;
    border-radius: @radius !important;
  }

  &:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057;
    border-radius: @radius !important;
  }
  
}
}

.color-switch {
  position: absolute;
  bottom: 20px;
  left: 30px;
  .description {
    color: fade(@ness_color_2, 50%);
    margin-top: 10px;
    font-size: 13px;
  }
  .p-inputswitch {
    float: left;
    margin-bottom: 5px;
    height: 24px;
    width: 46px;
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: @ness_color_2 !important;
      }
    }
  }
  .p-inputswitch .p-inputswitch-slider:before {
    height: 16px;
    width: 16px;
    left: 5px;
    margin-top: -8px;
  }
}

.mb-0 {
  margin-bottom: 0px;
}

body.dark {
  background-color: darken(@ness_color_2, 5%);
  color: @white;
  .sidebar {
    background-color: @ness_color_2 !important;
    border-right: 1px solid darken(@ness_color_2, 5%);
    ul.nav-menu li a {
      color: @white;
    }
  }
  .p-panelmenu .p-panelmenu-header > a {
    color: @white;
    background: @ness_color_2;
  }
  .p-panelmenu .p-panelmenu-content {
    color: @white;
    background: @ness_color_2;
  }
  .product-item .product-list-detail {
    background-color: @ness_color_2;
  }
  .drop-wrapper {
    background-color: darken(@ness_color_2, 5%);
  }
  .product-item .product-list-detail h4 {
    color: @ness_color_3;
  }
  .blu-box .p-card .p-card-body .p-card-content h3 {
    color: @ness_color_3;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: darken(@ness_color_2, 5%);
    border-radius: @radius !important;
    ;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: @white;
  }
  .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: @ness_color_3;
    background: @ness_color_3;
    color: @white !important;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    color: @white;
  }
  .p-card {
    background-color: @ness_color_2 !important;
    color: @white !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 8%) !important;
  }
  .projects .p-card .p-card-body .p-card-content {
    color: @white !important;
  }
  #main .navbar-top {
    background-color: @ness_color_2;
  }
  .p-tabview .p-tabview-nav {
    background-color: @ness_color_2 !important;
    border: 1px solid @ness_color_2 !important;
  }
  .p-datatable .p-datatable-tbody > tr {
    background-color: fade(@white, 10%) !important;
    color: @white;
  }
  .p-tabview .p-tabview-panels {
    background-color: @ness_color_2 !important;
    color: @white;
  }
  .blu-box .p-card .p-card-body .p-card-content {
    color: @white;
  }
  .p-inputtext {
    color: @white;
    background-color: darken(@ness_color_2, 5%);
    border-color: fade(@white, 20%);
  }
  .billings-list thead tr th, .p-datatable .p-datatable-thead > tr > th {
    background-color: darken(@ness_color_2, 5%);
    color: @white !important;
  }
  .p-dropdown {
    background-color: darken(@ness_color_2, 5%);
    color: @white !important;
    border-color: fade(@white, 20%);
  }
  .blu-box .p-card .p-card-body .p-card-content label.legend {
    color: fade(@white, 20%);
  }
  a {
    color: @white;
  }
  .color-switch .description {
    color: fade(@white, 20%);
  }
  .count-item {
    background-color: darken(@ness_color_2, 5%);
    color: @white !important;
  }
  .featured-card .p-card .p-card-body h4 {
    color: @white !important;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: transparent !important;
    color: @white !important;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    border-bottom: 1px solid darken(@ness_color_2, 6%);
  }
  .p-multiselect {
    background-color: darken(@ness_color_2, 5%);
    border: 1px solid #ced4da;
    border-color: fade(@white, 20%);
  }
  .p-sidebar {
    background-color: darken(@ness_color_2, 5%);
  }
  .p-inputtext::placeholder {
    color: fade(@white, 50%);
  }
  .sidebar .openbtn {
    color: @white !important;
    i {
      color: @white;
    }
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    border-color: @white;
    color: @white;
  }
  .color-switch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: fade(@white, 20%) !important;
  }
  .helpdesk-card .p-card-body {
    background-color: transparent !important;
    border-color: fade(@white, 50%);
  }
  .appointment-card .p-card-body {
    background-color: @background !important;
    border-color: fade(@white, 50%);
  }
  .p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
    color: @ness_color_2 !important;
    background: @white !important;
    border-radius: 7px;
    border: 1px solid @ness_color_3 !important;
    i {
      color: @ness_color_3 !important;
    }
  }
  .login-box .p-inputtext {
    color: @ness_color_2;
    background-color: @white;
    border: 1px solid fade(@ness_color_2, 40%) !important;
  }
  .login-box .p-inputtext::placeholder {
    color: fade(@ness_color_2, 50%);
  }
  .sidebar .logo-wrapper {
    background: url(src/assets/images/logo-white.svg) no-repeat left center;
    background-size: 80%;
  }
}

@import "media.less";