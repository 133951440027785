@import "colors.less";
.p-sm-1, .p-sm-2, .p-sm-3, .p-sm-4, .p-sm-5, .p-sm-6, .p-sm-7, .p-sm-8, .p-sm-9, .p-sm-10, .p-sm-11, .p-sm-12, .p-md-1, .p-md-2, .p-md-3, .p-md-4, .p-md-5, .p-md-6, .p-md-7, .p-md-8, .p-md-9, .p-md-10, .p-md-11, .p-md-12, .p-lg-1, .p-lg-2, .p-lg-3, .p-lg-4, .p-lg-5, .p-lg-6, .p-lg-7, .p-lg-8, .p-lg-9, .p-lg-10, .p-lg-11, .p-lg-12, .p-xl-1, .p-xl-2, .p-xl-3, .p-xl-4, .p-xl-5, .p-xl-6, .p-xl-7, .p-xl-8, .p-xl-9, .p-xl-10, .p-xl-11, .p-xl-12 {
  padding: 0px 15px;
}

.p-col, .p-col-1, .p-col-2, .p-col-3, .p-col-4, .p-col-5, .p-col-6, .p-col-7, .p-col-8, .p-col-9, .p-col-10, .p-col-11, .p-col-12 {

  padding: 0px 15px !important; 
}

.p-col-fixed {
  flex: 0 0 auto;
  padding: 0px 15px !important; 
}


.p-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 0rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 5px 1rem;
  font-size: 16px;
}

.p-datatable .p-datatable-tbody > tr.red > td {
  background-color: fade(@red,6%);
}



.p-overlaypanel .p-overlaypanel-content {
  min-width: 200px;
}

.p-selectbutton {
  width: auto;
  display: inline !important;
  .p-button {
    color: @ness_color_2 !important;
    background: @ness_color_1;

    &.p-highlight {
      background: @ness_color_3;
      border-color: @ness_color_3;
      color: #ffffff !important;
      border: 0px;
    }

    &:hover {
      background: @ness_color_2 !important;
      border: 0px solid @ness_color_1 !important;
      color: @white !important;
  }
  }
}


.p-sidebar-content {
  position: inherit;
  overflow-y: visible !important;
}

.p-progressbar {
  max-width: 100%;
  overflow: hidden !important;
  height: 20px;
  line-height: 20px !important;
  .p-progressbar-value {
    background-color: fade(@green,90%);
  }
  .p-progressbar-label {
    position: relative !important;
    font-size: 10px;
    line-height: 20px;
    float: right !important;
    right: 0px !important;
  }
}
.p-datatable .p-datatable-tbody > tr {
  color: @text-color;
}

.p-paginator {

      padding: 5px 0rem !important;

      .p-paginator-current {
        float: left;
        font-size: 14px;

      }

      .p-paginator-pages .p-paginator-page {
        background-color: transparent;
        border: 0 none;
        color: #6c757d;
        min-width: auto;
        height: auto;
        padding: 7px 10px;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        font-size: 14px;
      }

       .p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last {
        background-color: transparent;
        border: 0 none;
        color: #6c757d;
        min-width: auto;
        height: auto;
        font-size: 14px;
        padding: 7px 10px;

        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
    }
}




.p-button {
  color: @white !important;
  background: #ddd;
  border: 0px solid #ddd !important;
  padding: 0.4rem 0.5rem;
  font-size: 0.9375rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: @radius;
  font-family: "Circular", sans-serif;
  font-weight: 400;
  &:hover {
    background: #333 !important;
    border: 0px solid #333 !important;
    color: @white;
  }

  
  &.p-button-icon-only {
    width: auto !important;
    padding: 0.4rem 0.4rem !important;
  }

  &.p-button-small {
    padding: 0.25rem 0.4rem;
    font-size: 0.75rem;
    height: 26px;
    border-radius: 3px !important;

    &.p-button-icon-only {
        .p-button-icon {
          font-size: 12px !important;
        }
    }
  }

}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.5rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  font-size: 14px;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}


.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: @red !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: @red !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: @red !important;
  background: @red !important;
}

.p-button.p-button-white, .p-buttonset.p-button-white > .p-button, .p-splitbutton.p-button-white > .p-button {
  color: @ness_color_2 !important;
  background: @white;
  border-radius: @radius;
  border: 1px solid @white !important;
  &:hover {
    color: @white !important;
    background: darken(@ness_color_2, 20%) !important;
    border-radius: @radius;
    border: 1px solid @ness_color_2 !important;
  }
}


.p-button.p-button-options, .p-buttonset.p-button-options > .p-button, .p-splitbutton.p-button-options > .p-button {
  color: @ness_color_2 !important;
  background: @white;
  border-radius: @radius;
  border: 1px solid #ced4da !important;
  border-style: inset;

   .p-button-icon {
     color: @ness_color_3;
   }
  &:hover {
    color: @white !important;
    background: @ness_color_2 !important;
    border-radius: @radius;
    border: 1px solid @ness_color_2 !important;
    border-style: inset; 
   }
}


.p-button.p-button-back, .p-buttonset.p-button-back > .p-button, .p-splitbutton.p-button-back > .p-button {
  color: @ness_color_2 !important;
  background: @white;
  border-radius: @radius;
  border: 1px solid @ness_color_2 !important;
  border-style: inset;

   .p-button-icon {
     color: @ness_color_3;
   }
  &:hover {
    color: @white !important;
    background: @ness_color_2 !important;
    border-radius: @radius;
    border: 1px solid @ness_color_2 !important;
    border-style: inset; 
   }
}



.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: @white !important;
  background: @red;
  border-radius: @radius;
  border: 1px solid @red !important;
  &:hover {
    color: @ness_color_2;
    background: darken(@red, 20%) !important;
    border-radius: @radius;
    border: 1px solid @red !important;
  }
}

.p-button.p-button-default, .p-buttonset.p-button-default > .p-button, .p-splitbutton.p-button-default > .p-button {
  color: @white !important;
  background: @ness_color_2;
  border-radius: @radius;
  border: 1px solid @ness_color_2 !important;
  &:hover {
    color: @ness_color_2 !important;
    background: @white !important;
    border-radius: @radius;
    border: 1px solid @ness_color_2 !important;
  }
}

.p-button.p-button-goback, .p-buttonset.p-button-goback > .p-button, .p-splitbutton.p-button-goback > .p-button {
  color: @ness_color_2 !important;
  background: @white;
  border-radius: @radius;
  border: 1px solid @ness_color_2 !important;
  border-style: inset;
  &:hover {
    color: @white !important;
    background: @ness_color_2 !important;
    border-radius: @radius;
    border: 1px solid @ness_color_2 !important;
    border-style: inset;  }
}


.p-button.p-button-blue {
  color: @white !important;
  background: #5c6b84;
  border-radius: @radius;
  border: 1px solid #5c6b84 !important;
  &:hover {
    color: @ness_color_2;
    background: darken(@red, 20%) !important;
    border-radius: @radius;
    border: 1px solid @red !important;
  }
}

.btn-block {
  display: block;
  width: 100% !important;
}

.p-button.p-button-dark {
  color: @white !important;
  background: @ness_color_2;
  border-radius: @radius;
  border: 1px solid @ness_color_2 !important;
  &:hover {
    color: @white;
    background: @ness_color_2 !important;
    border-radius: @radius;
    border: 1px solid @ness_color_2 !important;
  }
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: @white;
  background: @ness_color_2;
  border-radius: @radius;
  border: 1px solid @ness_color_2 !important;
  &:hover {
    color: @ness_color_3 !important;
    background: @white !important;
    border-radius: @radius;
    border: 1px solid @ness_color_3 !important;
  }
}


.p-button.p-button-add, .p-buttonset.p-button-add > .p-button, .p-splitbutton.p-button-add > .p-button {
  color: @white !important;
  background: @ness_color_3;
  border: 1px solid @ness_color_3 !important;
  border-radius: @radius;

  &:hover {
    color: @white;
    background: @ness_color_2 !important;
    border: 1px solid @ness_color_2 !important;
  }
}



.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: @white;
  background: @ness_color_7;
  border-radius: @radius;
  border: 1px solid @ness_color_7 !important;
  &:hover {
    color: @ness_color_2;
    background: darken(@ness_color_7, 20%) !important;
    border-radius: @radius;
    border: 1px solid @ness_color_7 !important;
  }
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: @white;
  background: @ness_color_2;
  border-radius: @radius;
  border: 1px solid @ness_color_2 !important;
  &:hover {
    color: @ness_color_2;
    background: darken(@green, 20%) !important;
    border-radius: @radius;
    border: 1px solid @green !important;
  }
}



.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: @white;
  background: @ness_color_6;
  border-radius: @radius;
  border: 1px solid @ness_color_6 !important;
  &:hover {
    color: @ness_color_2;
    background: darken(@ness_color_6, 20%) !important;
    border-radius: @radius;
    border: 1px solid @ness_color_6 !important;
  }
}

.p-button.p-button-primary, .p-buttonset.p-button-primary > .p-button, .p-splitbutton.p-button-primary > .p-button {
  color: @white !important;
  background: @ness_color_3;
  border: 1px solid @ness_color_3 !important;
  border-radius: @radius;
  //box-shadow: 0 0.125rem 0.25rem 0 rgb(105 108 255 / 40%);

  &:hover {
    color: @white;
    background: @ness_color_2 !important;
    border: 1px solid @ness_color_2 !important;
  }
}

.p-dialog-footer {
  .p-button {

    border-radius: @radius;
    &:hover {
      color: @ness_color_2;
      background: darken(@green, 20%) !important;
      border-radius: @radius;
      border: 0px solid @green !important;
    }
  }
  .p-confirm-dialog-reject {
    color: @white !important;
    background: @red;
    border-radius: @radius;
    border: 0px solid @red !important;
    &:hover {
      color: @ness_color_2;
      background: darken(@red, 20%) !important;
      border-radius: @radius;
      border: 0px solid @red !important;
    }
  }
}


.truncate {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 15px;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
}

.p-tree {
  border: 0px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0;
  border-radius: 0px;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border-color: @ness_color_3;
}

.p-inputtext:enabled:hover {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border-color: @ness_color_3;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: @ness_color_3;
}


/* checkbox */
.p-tag {
  height: 24px;
  background-color: #B1E5FC;
  border: 0px solid @ness_color_2;
  color: #1A1D1F;
  font-size: 12px;
}

.p-tag.p-tag-success {
  background-color: @green !important;
  color: #ffffff;
  border: 1px solid @green;

}

.p-tag.p-tag-danger {
  background-color: @red !important;
  color: #ffffff;
  border: 1px solid @red;

}

.p-tag.p-tag-info {
  background-color: @ness_color_6 !important;
  color: #ffffff;
  border: 1px solid @ness_color_6;

}

.p-tag.p-tag-warning {
  background-color: @ness_color_7;
    color: #212529;
    border: 1px solid @ness_color_7;

}


.p-checkbox .p-checkbox-box.p-highlight {
  border-color: @red;
  background: @red;
  &:hover {
    border-color: @red;
    background: @red;
  }
}



.p-sidebar-right {
  height: 100%;
  right: 0;
  top: 0;
  min-width: 500px !important;
}



/* table */

.fc-toolbar h2 {
  font-size: 1.375em;
  margin: 0;
}

.p-datatable .p-datatable-tbody > tr > td:first-child {
  padding: 10px 5px 10px 15px !important;
}

.p-datatable .p-datatable-tbody > tr > td:last-child {
  padding: 10px 5px !important;
}

.p-datatable .p-datatable-header {
  padding: 0px 0px 20px 0px !important;
}

.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  padding: 10px;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: @white;
  color: #495057;
}


.event-item {

  background: fade(@white, 90%);
  box-shadow: @shadow;
  border: 1px solid #dee2e6;
  border-left: 6px solid @ness_color_3;
  color: @ness_color_2;
  padding: 10px;
  border-radius: @radius;
  width: 100%;

  .event-client-name {
    color: @ness_color_3 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }

  &:hover {
    box-shadow: none !important;
  }
}

.fc-icon {
  font-family: 'primeicons' !important;
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  height: 1em;
}

label {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
}


.p-datepicker table td {
  padding: 0rem;
}

.p-datepicker table td > span {
  width: 2rem;
  height: 2rem;
  border-radius: 8px !important;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
}


.p-datepicker table td.p-datepicker-today > span {
  background: @ness_color_2 !important;
  color: @white !important;
  border-color: transparent;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: @ness_color_2;
  color: @ness_color_2;
}



.p-tabview .p-tabview-panels {
  padding: 1rem 0px;
}


.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 10px 6px;
  margin-right: 10px;
  font-weight: 500;
}


.p-card-body {
  h6 {
    padding: 0px !important;
    margin: 0px;
    font-weight: 400;
    font-size: 16px;
    color: @ness_color_2 !important;
  }
  h1 {
    margin-top: 0px;
    font-size: 24px;
  }
  h4 {
    margin-top: 0px;
  }
  h3.date {
    color: @ness_color_3 !important;
  }
  p {
    margin: 0px !important;
    padding: 0px !important;
  }
}

.p-card .p-card-title {
  font-size: 1.1rem !important;
  font-weight: 700;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 7px !important;
  margin-bottom: 1rem !important;
}

.drop-wrapper {
  background-color: #f5f5f5;
  height: 60vh;
  border-radius: 4px;
  padding: 10px;
  position: relative;


  &::after {
    content: "Drop Here";
    text-align: center;
    position: absolute;
    color: #ccc;
    top: 45%;
    width: calc(100% - 60px);
  }

}

.drop-column {
  border: 2px dotted #ccc;
  transition: border-color 0.2s background-color 0.2s;
  padding: 10px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  position: relative;
  z-index: 100;

  

  &.p-draggable-enter {
    border-color: #ddd;
    background-color: #ddd;
  }

  .drop-item {
    .product-list-detail {
      flex: 1 1 0;
      background-color: @white;
      padding: 15px 20px;
      border-radius: 4px;
      position: relative;
      line-height: 30px;
      border-bottom: 0px solid #ccc;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
      margin-bottom: 10px;
      position: relative;
      z-index: 10;

      .icon {
        background-color: @ness_color_3;
        height: 30px;
        width: 30px;
        color: @white;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        float: left;
        margin-right: 20px;
      }

      h5 {
        padding: 0px;
        margin: 0px;
      }

      .trash-button {
        position: absolute;
        right: 15px !important;
        top: 12px !important;
      }
    }
  }
}

.product-item {
  display: flex;
  align-items: center;
  padding: 0rem;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 0px solid var(--surface-d);

  .product-list-detail {
    flex: 1 1 0;
    background-color: @white;
    padding: 15px 15px;
    border-radius: 0px;
    line-height: 30px;
    position: relative;
    border-bottom: 0px solid #ccc;
    border-radius: 6px;

    .drag-me {
      right: 30px;
      top: 10px;
      position: absolute;
    }

    .drag-me:before {
      content: ".";
      font-size: 20px;
      line-height: 20px;

      color: @ness_color_3;
      text-shadow: 0 5px @ness_color_3, 0 10px @ness_color_3,
        5px 0 @ness_color_3, 5px 5px @ness_color_3, 5px 10px @ness_color_3,
        10px 0 @ness_color_3, 10px 5px @ness_color_3, 10px 10px @ness_color_3;
    }

    .icon {
      background-color: @ness_color_3;
      height: 30px;
      width: 30px;
      color: @white;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      float: left;
      margin-right: 20px;
    }

    hr {
      border-bottom: 1px dotted #ccc;
      margin-bottom: 5px;
    }
  
    p.date {
      padding-top: 6px !important; 
    }

    h6 {
      padding: 0px;
      margin: 0px;
      line-height: 110%;

    }

    h4 {
      padding: 0px;
      margin: 0px;
      line-height: 110%;

    }


    h5 {
      padding: 0px;
      margin: 0px;
      line-height: 110%;
      font-weight: 500;
      padding-top: 6px !important; 

    }
  }

  .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .product-category {
    vertical-align: middle;
    line-height: 1;
  }
}

[pDraggable] {
  cursor: move;
}


body .ui-panel .ui-panel-titlebar {
  cursor: grab !important;
}

body .ui-panel .ui-panel-titlebar:hover {
    cursor: grab !important;
  }
  .p-inputtext {

    height: 36px;
  }


  .p-inputtextarea {
    height: 150px;
  }

  .p-inputgroup-addon:first-child, .p-inputgroup button:first-child, .p-inputgroup input:first-child {
    border-radius: 0px !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }
  

.p-inputgroup .p-button.p-button-primary {
  border-radius: 0px;
  padding-left: 15px;
  padding-right: 15px;
  
}

.p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup input:last-child {
  border-radius: 0px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  padding-right:11px !important;
  border-radius: 0px 6px 6px 0px !important;
  padding-left: 16px;
}

.p-sidebar-content {

  .p-card {
    background: #ffffff;
    color: #282d35;
    border-radius: 7px;
    border: 1px solid #dee2e6;
    margin-bottom: 20px;
}
}
